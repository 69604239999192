.sweet-overlay{
    background: rgba(255,255,255,.8);
}

.sweet-alert{
    background: $color_pink;
    color: #fff;
    border-radius: 0;
    font-family: Lora;


    line-height: 1.5em;
    font-size: 12px;

    h2{
        color: #fff;
        font-weight: 300;
    }

    &.show-sweet-alert{
        animation: spaceInUp 555ms ease-out;

        &.shake{
              animation: shake 1s;
        }
    }

    .button{
        margin: 0 3px;
    }

    input[type=text]{
        border-bottom: 1px solid #fff;
        background: transparent;
        color: #fff;
        max-width: 200px;
        display: block;
        margin: auto;
    }
    .sweet-content{
        padding: 30px 20px 0;
    }

    p{
        font-size: inherit;
        line-height: 1.6em;
        margin-bottom: 1em;
    }
    .sweet-spacer{
        margin: 0;
        background: none;
    }

    .sweet-close{
        font-size: 50px;
        color: #fff;
        &:hover{
            color: #fff;
        }
    }

    .sweet-confirm.styled{
        // background: #fff;
        background: transparent;
        border: 1px solid #fff;
        // color: $color_pink;
        color: #fff;
        border-radius: 0;
    }

    @include breakpoint(medium){
        font-size: 20px;

    }

}


.sweetalert-blue{
    background: $color_blue;
    .sweet-icon.sweet-success:after,
    .sweet-icon.sweet-success .fix,
    .sweet-icon.sweet-success:before{
        background: $color_blue;
    }
}
