
.download-chose{
	margin-bottom: 3em;
	.circle-button{
		display: block;
		vertical-align: middle;
		position: relative;

		&:hover .circle{
			background: $color_blue;
			img{
				transform: scale(1.1);
				transition-timing-function: ease-in-out;
			}
		}
	}
	.circle-button .circle{
		vertical-align: middle;

		padding: 20px;
		background: $color_pink;
		border: 4px solid #fff;
		display: block;


		width: 100px;
		margin: 0 auto 0px;

		border-radius: 200px;
		transition: 333ms;

		img{
			display: block;
			transition: 444ms;
		}


	}

	.title{
		text-transform: uppercase;
		display: block;
		color: #fff;
		margin: 1.5em  auto 2em;
		// position: absolute;
		// top: 100%;
		text-align: center;
		// left: 50%;
		// min-width: 100%;
        // width: 180%;
		// transform: translateX(-50%);

        font-size: 11px;
        line-height: 1.3;
	}

	.dots{
        display: block;
		margin:auto;
		vertical-align: middle;
		padding: 90px 2% 0;
        max-width: 80%;
	}


    @include breakpoint(medium){

    	.title{
            font-size: 14px;
        }
        .circle-button .circle{
            padding: 40px;
			width: 180px;
        }
    }

    @include breakpoint(large){

    	.title{
            font-size: 14px;
        }
        .circle-button .circle{
            padding: 50px;

			width: 210px;
        }
    }

}
