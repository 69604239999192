.employees{

    margin: 5% -5%;


}

.employee{
    text-align: center;
    color: $color_pink;
    padding: 2%;
    margin-bottom: 2%;
    font-family: Lato;


    .photo{
        margin: auto;
        width: 30%;
        transition: 666ms;

        transform-origin: bottom center;
        border-radius:400px;
        background: $color_pink;
        img{ width: 100%; transition: 888ms; }
    }

    &:hover .photo{
        background: $color_blue;
        img{
            transform: scale(1.2);
        }
    }
    .name{

        @extend h6;
        font-family: Lato;


        font-weight: bold;
        margin-top: 1em;
        margin-bottom: 0;
    }
    .title{
        font-family: Lato;
        font-style: italic;
        font-size: .9rem;
    }

    // &:hover .photo{
    //     // background: $color_blue;
    // }

    &:last-child:nth-child(3n+1){
        // float: left;
        // margin-left: 50%;
        // transform: translateX(-50%);

        float: none;
        margin-left: auto;
        // transform: translateX(-50%);
        margin-right: auto;
        clear: both;
    }


    @include breakpoint(medium){
        .name{
            margin-top: 2em;
        }
        .photo{

            width: 75%;
        }
    }
}



.employee-info{

	position: absolute;
    left: 0;
    right: 0;
    z-index: 11;

	background: #ccc;

    transition: top 222ms ease-in-out;


    // &:after,
    // &:before{
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     background: $color_blue;
    //
    // }
    // &:before{
    //     right: 100%;
    //     width: 50%;
    // }
    // &:after{
    //     left: 100%;
    //     width: 50%;
    // }

    .arrow {
    	bottom: 100%;
    	left: 50%;
    	border: solid transparent;
    	content: " ";
    	height: 0;
    	width: 0;
    	position: absolute;
    	pointer-events: none;
    	border-color: rgba(204, 204, 204, 0);
    	border-bottom-color: $color_blue;
    	border-width: 30px;
    	margin-left: -30px;

        transform: translateY(60px);

        transition: left 333ms ease-in-out, transform 222ms 222ms;


    }


    .content{
        padding: 50px 10px;
        color: #fff;
        text-align: center;
        opacity: 0;
        transition: opacity 222ms 111ms;

    }


    &.active{
        box-shadow: rgba(0,0,0,.3) 0 10px 10px 0;
        .arrow{
            transform: translateY(0);
        }
        .content{
            opacity: 1;
        }
    }

}


.employees__institution-chose{
    text-align: center;
    margin: 2rem 0 0;
}

.employees__institution-chose .employee{
    
    

    // &.active{

    //     border-bottom: 1px solid $color_pink;

    //     &:after{
    //         content: '';
    //     	top: 100%;
    //         left: 50%;
    //         border: solid transparent;
    //         content: " ";
    //         height: 0;
    //         width: 0;
    //         position: absolute;
    //         pointer-events: none;
    //         border-color: rgba(136, 183, 213, 0);
    //         border-top-color: $color_pink;
    //         border-width: 10px;
    //         margin-left: -10px;
    //     }
    // }
}