.organization-icons{

    margin-top: 3em;

    .icon{
        margin-bottom: 1em;
    }

    .title{
        font-size: 20px;
        margin-bottom: 0.5em;
    }

}
