
.primary-menu {
	margin: auto;
	text-align: center;
	display: none;

	@include breakpoint(xlarge){
		display: block;
	}

	a{
		outline: none;
	}
}
.primary-menu > ul{
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 13px;

	> li{
		display: inline-block;
		font-family: Lato;
		font-weight: bold;
		position: relative;

		> a{
			position: relative;
			display: inline-block;
			padding: 30px 15px 10px;
			color: #0ca8fc;
		}
		a:before{
			content: '';
			position: absolute;
			top: 0px;
			left: 50%;
			margin-left: -10px;
			background: url(../img/heart.png) center center no-repeat;
			width: 19px;
			height: 19px;
			opacity: 0;
			transition: 333ms;
		}

		&:hover > a:before,
		&.active > a:before{
			top: 10px;
			opacity: 1;
		}

	}
	li:hover > ul.submenu{
		display: block;
		animation: drift 444ms ease-in-out;
	}


@include breakpoint(large){
	ul.submenu.submenu-level-1{
		position: absolute;
		min-width: 150px;

		top: 60px;
		background: rgba(255,255,255,.9);
		box-shadow: rgba(255,255,255,.9) 0 0 2px 0;
	}

	 ul.submenu{
		list-style: none;
		margin: 0;
		padding: 10px 15px 10px;
		
		text-align: left;
		display: none;
		
		border-radius: 5px;

		> li{
			font-weight: normal;
			padding: 0;
			margin: 0;
			position: relative;
			display: block;



			> a{
				line-height: 1.1;
				display: block;
				padding: 0.65em 0;
				position: relative;


				&:before{
					position: absolute;
					left: -20px;
					top: 50%;
					opacity: 0;
					margin-top: -10px;
				}
				&:hover{
					color: $color-pink;
					&:before{
						opacity: 1;
					}
				}
			}
		}
	}
}

}//.primary-menu


.icons-nav{
	position: absolute;
	top: 20px;
	right: 0px;
	ul{
		list-style: none;
		margin: 0;
	}

	.badge{
		position: absolute;
		top: -5px;
		right: -5px;
		line-height: 11px;
		font-family: Lato;
		font-size: 14px;
		font-weight: bold;
		height: 24px;
		min-width: 24px;
		border: 2px solid #fff;
		// font-size: 11px;
	}
	> ul > li{
		display: inline-block;
		float: left;
		position: relative;
		color: $color_pink;
		fill: $color_pink;
		cursor: pointer;
		transition: color 1s;
		padding: 0 5px;
		text-align: left;

		a{
			display: block;
			color: $color_pink;
			fill: $color_pink;
			transform: scale(1);
		}


		svg{
			width: 30px;
			height: 30px;
			font-size: 30px;

			&.contact{
				width: 50px;
			}

		}
		&:not(.menu) a:hover{

			color: $color_blue;
			fill: $color_blue;
			animation: plump 1000ms linear both;
			// animation: pulse 333ms ;
		}

	}

	@include breakpoint(large){
		right: 0;
		top: 40px;

		> ul > li{
			padding: 0 8px;
			svg{
				width: 40px;
				height: 40px;
				font-size: 40px;
			}
		}
	}
}
