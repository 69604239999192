



.mobile-menu{
	position: absolute;
	top: 70px;
	left: 0;
	right: 0;
	display: none;
	background:  $color_blue;
	color: #fff;
	padding: 30px 20px;


	font-family: Lato;
	font-size: 15px;
	text-align: center;
	ul{

		list-style: none;
		margin: 0;

		 li{
			margin-bottom: .5em;
			position: relative;
			a{
				display: block;
			}

			a:before{

				position: absolute;
				top: 0px;
				left: 50%;
				margin-left: -10px;
				background: url(../img/heart.png) center center no-repeat;
				width: 19px;
				height: 19px;
				opacity: 0;
				transition: 333ms;
			}

			&.active{
				a:before{
					content: '';
					opacity: 1;
					top: -13px;
				}
			}

		}
		li > ul{
			list-style: none;
			margin: 1rem 0 0;
			padding: 1rem 0 .5em;
			display: none;
			overflow: visible;
			background: rgba(0,0,0,.05);
			position: relative;

			&:before{
			
				bottom: 100%;
				left: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(255, 255, 255, 0);
				border-bottom-color: rgba(0,0,0,.05);
				border-width: 10px;
				margin-left: -10px;
			
			}

		}

		li > a{
			font-weight: bold;

		}

		li.open{
			> ul{
				display: block;
			}
		}


		a{
			color: #fff;
		}
	}

	&.active{
		display: block;
	}

	@include breakpoint(large){
		top: 130px;
	}

}

.mobile-menu-icons{

	a{
		color: $color_pink;
		fill: $color_pink;
		display: inline-block;
		width: 40px;
		height: 40px;
		margin: 10px;
		filter: drop-shadow( 1px 1px 1px rgba(0,0,0,.2) );

		svg{

			width: 40px;
			height: 40px;

		}

		&:hover{

			color: #fff;
			fill: #fff;
		}

	}
}




/* HAMBURGER */

$height-icon: 30px;
$width-line: 30px;
$height-line: 3px;

$transition-time: 0.4s;
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;



.hamburger-icon {
  width:$width-line;
  height:$height-icon;

  position:relative;
  display:block;
  margin: 0;

  .line {
    display:block;
    background: $color_pink;
    width:$width-line;
    height:$height-line;
    position:absolute;
    left:0;
    border-radius:($height-line / 2);
    transition: all $transition-time;
    -webkit-transition: all $transition-time;
    -moz-transition: all $transition-time;

    &.line-1 {
      top:6%;
    }
    &.line-2 {
      top:44%;
    }
    &.line-3 {
      top:86%;
    }
  }
  &:hover, &:focus {
     .line-1 {

        transform: translateY($height-line / 2 * -1);
        -webkit-transform: translateY($height-line / 2 * -1);
        -moz-transform: translateY($height-line / 2 * -1);
     }
    .line-3 {

        transform: translateY($height-line / 2);
        -webkit-transform: translateY($height-line / 2);
        -moz-transform: translateY($height-line / 2);
    }
  }
  &.active {
    .line-1 {
		 top: 0%;
      transform: translateY($translateY) translateX($translateX) rotate($rotation);
      -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
      -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
    }
    .line-2 {
      opacity:0;
    }
    .line-3 {
		top: 100%;
      transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
      -webkit-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
      -moz-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
    }
  }

  @include breakpoint(large){
	  width: 40px;
	  height: 34px;
	 .line {
		 height: 4px;
		 width: 40px;
	 }
  }
}
