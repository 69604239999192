

h1,h2,h3,h4{
	line-height: 1;
}

.h1{  @extend h1; }
.h2{  @extend h2; }
.h3{  @extend h3; }
.h4{  @extend h4; }
.h5{  @extend h5; }
.h6{  @extend h6; }

.text-14{
	font-size: 12px;
}

.text-18{
	font-size: 18px;
}

.text-20{
	font-size: 20px;
}

.text-28{
	font-size: 28px;
}

@include breakpoint(medium){
	.text-14{
		font-size: 14px;
	}
}
