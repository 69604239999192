
.slider-handle{
    background-image: linear-gradient(-149deg, $light-gray 50%, $medium-gray 100%);
    box-shadow: $medium-gray 1px 1px 1px 0;

    &.is-dragging{
        box-shadow: $medium-gray 1px 1px 1px 0,
                    $primary-color 1px 1px 3px 0;
    }
}
