// Color pallete

$color_0099ff: #0099ff;
$color_000000: #000000;
$color_black:  #000000;
$color_253137: #253137;
$color_5f7c8a: #5f7c8a;
$color_afbdc4: #afbdc4;
$color_ffffff: #ffffff;
$color_white:  #ffffff;
$color_ffc700: #ffc700;
$color_yellow: #ffc700;
$color_6dd100: #6dd100;
$color_ff1643: #ff1643;
$color_ff3636: #ff3636;
$color_00e4ff: #00e4ff;
$color_0059bd: #0059bd;

$color_blue: #0092ff;
$color_pink: #ff0074;
$color_gray: #f4f7f9;

@mixin color-helper-classess {
    // TEXT COLORS
    // .color-0099ff{ color: $color_0099ff }
    // .color-blue{ color: $color_0099ff }
    // .color-000000{ color: $color_000000 }
    // .color-black{ color: $color_black }
    // .color-253137{ color: $color_253137 }
    // .color-5f7c8a{ color: $color_5f7c8a }
    // .color-afbdc4{ color: $color_afbdc4 }
    // .color-ffffff{ color: $color_ffffff }
    .color-white{ color: $color_white }
    // .color-ffc700{ color: $color_ffc700 }
    // .color-6dd100{ color: $color_6dd100 }
    // .color-ff1643{ color: $color_ff1643 }
    // .color-ff3636{ color: $color_ff3636 }
    // .color-00e4ff{ color: $color_00e4ff }
    // .color-0059bd{ color: $color_0059bd }
    // .color-ff3636{ color: $color_ff3636 }
    .color-blue{ color: $color_blue }
    .color-pink{ color: $color_pink }
    .color-gray{ color: $color_gray }

    // BACKGROUNDS
    // .bgcolor-0099ff{ background-color: $color_0099ff }
    // .bgcolor-blue{ background-color: $color_0099ff }
    // .bgcolor-000000{ background-color: $color_000000 }
    // .bgcolor-black{ background-color: $color_black }
    // .bgcolor-253137{ background-color: $color_253137 }
    // .bgcolor-5f7c8a{ background-color: $color_5f7c8a }
    // .bgcolor-afbdc4{ background-color: $color_afbdc4 }
    // .bgcolor-ffffff{ background-color: $color_ffffff }
    // .bgcolor-white{ background-color: $color_white }
    // .bgcolor-ffc700{ background-color: $color_ffc700 }
    // .bgcolor-6dd100{ background-color: $color_6dd100 }
    // .bgcolor-ff1643{ background-color: $color_ff1643 }
    // .bgcolor-ff3636{ background-color: $color_ff3636 }
    // .bgcolor-00e4ff{ background-color: $color_00e4ff }
    // .bgcolor-0059bd{ background-color: $color_0059bd }

    .bgcolor-blue{ background-color: $color_blue }
    .bgcolor-pink{ background-color: $color_pink }
    .bgcolor-gray{ background-color: $color_gray }
}
