.square-gallery-container{
    @extend .lato;

    overflow: hidden;
}
.square-gallery-wrapper{
    transition: height 555ms ease-in-out;
}
.more-button-wrapper{
    padding: 3em 0;
    .button{
        margin: 0;
        text-transform: uppercase;
        min-width: 200px;
        position: relative;
        overflow: hidden;

        .expanded{
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -20px;
            transform: translateY(-250%);
            transition: 555ms 555ms;

        }

        .hidden{

            transition: 555ms 555ms;
            display: inline-block;

        }

        &.active{
            .expanded{
                transform: translateY(-50%);

            }

            .hidden{

                transform: translateY(300%);

            }
        }
    }
}

.square-gallery-item{


    padding: 0px;

    &:last-child:not(:first-child){
        float: left;
    }


    &_inside{
        position: relative;
        cursor: pointer;

    }

    // &.active &_image{
    //     box-shadow: inset $color_blue  0 0 0 4px;
    // }


        &_image{
            padding-bottom: 100%;
            background-position: center;
            background-size: cover;

        }

        &_apla{
            background: rgba($color_blue, .8);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            transform: scale(.8);
            transition: 333ms;

            .title{
                text-transform: uppercase;
                padding: 0 1em;
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                font-weight: bold;
                letter-spacing: 0.1em;
            }

        }

    &_title{
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #000;
        padding: 0.5em 0 0;
    }
    &_date{
        color: #555;
        text-align: center;
        font-size: 14px;
         @extend .lato;
    }



    width: ( 100% * 1/2 );

    &.wide{
        width: ( 200% * 1/2 );
    }
    &.wide &_image{
        padding-bottom: 50%;
    }

    @include breakpoint(phone){

        width: ( 100% * 1/3 );
        &.wide{
            width: ( 200% * 1/3 );
        }


        &_inside:hover &_apla{
            opacity: 1;
            transform: scale(1);
        }
    }

    @include breakpoint(medium){

        width: ( 100% * 1/4 );
        &.wide{
            width: ( 200% * 1/4 );
        }


        &_inside:hover &_apla{
            opacity: 1;
            transform: scale(1);
        }
    }



    @include breakpoint(large){

        width: ( 100% * 1/6 );

        &.wide{
            width: ( 200% * 1/6 );

        }


    }



}



.pagination{
    @extend .lato;

    text-align: center;
    margin: 2em 0 1em;

    ul{
        list-style: none;
        padding: 0;
        margin: 0;
    }
    li{
        color: #888;
        display: inline-block;
        border: 1px solid #eee;
        border-radius: 0;
        width: 35px;
        height: 35px;
        vertical-align: middle;
        line-height: 33px;
        text-align: center;
        overflow: hidden;
        &:hover{
            border-color: $color_pink;
        }
    }

    li.active{
        background: $color_pink;
        color: #fff;
    }

    a{
        border-radius: 0;
        background: inherit;
        color: inherit;
        display: inline-block;
        width: inherit;
        height: inherit;
        padding: 0;
        margin: 0;

        &:hover{
            background: transparent;
        }

    }


    @include breakpoint(large){
        li{
            margin: 0 2px;
        }
    }

}
