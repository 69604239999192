.primary-header{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	z-index: 11;
	.container{
		margin: auto;
		max-width: 1260px;
		padding: 0 0px 10px ;
		height: 62px;
		background: #fff;
	}

	.logo{
		position: absolute;
		left: 10px;
		top: 10px;
		width: 150px;
	}

	@include breakpoint(medium){
		.logo{
			max-width: 20%;
			width: auto;
			left: 10px;
			top: 20px;
		}

		.container{
			padding: 10px;
			height: auto;
			background: transparent;
		}
	}

	@include breakpoint(large){
		left: 0;
		.logo{
			max-width: 200px;
		}
	}

	@include breakpoint(xxlarge){

		.logo{
			max-width: 20%;
		}
	}

}
