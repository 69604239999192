.frog-butterfly-ladybug{

	display: none;

	bottom: 0px;
	width: 100%;
	right: 10%;
	position: absolute;

    padding-bottom: 100%;

	&.left{
		right: auto;
		left: 5%;
	}

    > div{
        position: absolute;
        max-width: 50%;
		img{
			position: relative;;
		}
    }

    .frog{
        bottom: 0;
        left: 40%;
		img{
			transform-origin: bottom center;
			 animation: frogBounce 3s 3s ease-in-out infinite ;
		}
    }

    .butterfly{
        bottom: 100%;
        left: 0;
    }

    .butterfly2{
		div{ transform: rotate(50deg) scale(.8); }
		img{
			animation-duration: 10s;
		}
        bottom: 100%;
        right: -10%;
    }

    .ladybug{

        bottom: 80%;
        right: 0%;

    }




	@include breakpoint(medium){
		display:block;
	}
}


.frog-butterfly-ladybug.show-on-all{
	display: block;
}
