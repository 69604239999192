.pswp__bg{
    background: rgba(255,255,255,.95);
}
.pswp__top-bar, .pswp__caption{
    background: transparent;
}
.pswp__ui--idle .pswp__button--arrow--left,
.pswp__ui--idle .pswp__button--arrow--right,
.pswp__ui--idle .pswp__top-bar{
    opacity: 1;
}
.pswp__button--close{
    color: $color_blue;
    fill: $color_blue;
    padding: 5px;
    background: none;
    svg{
        width: 30px;
        height: 30px;
        position: relative;
        z-index: -1;
    }
}

.pswp__img--placeholder--blank{
    background: $color_blue;
}

.pswp__button--arrow--left, .pswp__button--arrow--right{
    background: none;
    font-size: 30px;
    text-align: center;
    color: $color_blue;
    i{
        z-index: -1;
        position: relative;
    }
    &:before{
        display: none;
    }
}

.pswp__counter{
    color: $color_blue;
    bottom: 0;
    top: auto;
    left: 50%;
}
