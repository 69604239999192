$button-round-radius: 50px;
.button {
    -webkit-font-smoothing: auto;
    border-radius: 0;
    font-size: 300;
}

.button.active{
    background: darken( $color_blue, 10% );
}
.button .icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.35em;
    margin-left: 5px;
}
.button.large .icon {
    width: 1.6em;
    height: 1.6em;
}
.button.tiny {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}
.button.medium {
    padding-top: 1em;
    padding-bottom: 1em;
}
.button.round {
    border-radius: $button-round-radius;
}
.button.hollow {
    border-width: 2px;
    font-size: 1rem;
    padding: 1.3rem 2rem;
    letter-spacing: 0.1em;
}
.button-group {
    .button:not(:first-child) .button:not(:last-child) {
        border-radius: 0;
    }
}
.button.button-white {
    // Colors
    @include button-style(#fff, rgba(255,255,255,.8), #000);
    &.hollow {
        @include button-hollow;
        @include button-hollow-style(#fff);
        border-color: rgba(255,255,255,.5);

        &:focus,
        &:hover {
            border-color: rgba(255,255,255,.8);
            color: rgba(255,255,255,.8);
        }
    }
}
.button.button-pink {
    // Colors
    @include button-style($color_pink, darken($color_pink,5%), #fff);
    &.hollow {
        @include button-hollow;
        @include button-hollow-style($color_pink);
        &:focus,
        &:hover {
            // border-color: rgba(255,255,255,.8);
            // color: rgba(255,255,255,.8);
        }
    }

    .icon{
        fill: #fff;
        color: #fff;
        width: 1.2em;
        height: 1.2em;
        vertical-align: -0.25em;
    }
}
.button.button-yellow {
    // Colors
    @include button-style($color_yellow, $color_yellow, #000);
    &.hollow {
        @include button-hollow;
        @include button-hollow-style($color_yellow);
    }
}
@include breakpoint(small only) {
    .button.large {
        font-size: 16px;
    }
}
