html.debug:after{
    content: 'small';
    padding: 3px;
    color: #000;
    background: rgba(255,255,255,.7);
    font-size: 10px;
    font-family: arial;
    position: fixed;
    bottom: 0;
    left: 0;
}

@include breakpoint(phone){

    html.debug:after{
        content: 'phone';
    }
}

@include breakpoint(medium){

    html.debug:after{
        content: 'medium';
    }
}

@include breakpoint(large){

    html.debug:after{
        content: 'large';
    }
}

@include breakpoint(xlarge){

    html.debug:after{
        content: 'xlarge';
    }
}
