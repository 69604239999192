
.light::-webkit-input-placeholder { /* Chrome/Opera/Safari */
 color: rgba(255,255,255,.7);
}
.light::-moz-placeholder { /* Firefox 19+ */
 color: rgba(255,255,255,.7);
}
.light:-ms-input-placeholder { /* IE 10+ */
 color: rgba(255,255,255,.7);
}
.light:-moz-placeholder { /* Firefox 18- */
 color: rgba(255,255,255,.7);
}


[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{

    padding-left: 0.5rem;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;



    // COLORS
    &.light{
        color: #fff;
        padding-left: 0;
        padding-bottom: .9em;
        // font-size: 20px;
        font-family: Lato;
        border-bottom-color: #fff;


    }



    &:focus{
        background: rgba(255, 255, 255, 0.08);

        box-shadow: none;
        border-color: transparent;
        border-bottom-color: #999;

        .form-blue &{

            border-bottom-color: #fff;
        }
    }

    &.space{
        padding-bottom: 1em;
        padding-top: 1em;
        height: 4em;
    }

    &.round{
        border-radius: 3px;
    }
    &.small{
        font-size: .85rem;
        height: 2.8em;
        margin-bottom: .8rem;
        padding-top: .3em;
        padding-bottom: .3em;
    }
    &.large{
        font-size: 1.2rem;
        height: 2.8em;
        margin-bottom: 1rem;
    }


    &.error{
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom-color: $color_pink;
        border-bottom-width: 3px;
        color: $color_pink;
        .form-blue &{
            color: #fff;
            border-bottom-color: $color_pink;
        }
    }
}

.form-error-container{
    color: $color_pink;
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
    text-decoration: underline;

}

@include breakpoint(large){
    .form-error-container{

        font-size: 20px;

    }

    .contact-form{
        padding-left: 20px;
    }
}
