.slick-slide{
    outline: none;
}


.slick-offer{

    // font-size: 12px;
    p{
        font-family: Lato, sans-serif;
        line-height: 1.25;
    }

    .slick-slide{
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        text-align: center;
    }
    .icon{
        width: 46px;
        display: block;
        position: static;
        margin: 0 auto 20px;
        left: 10px;
    }

    .title{
        // font-weight: bold;
        font-size: 16px;
        margin-bottom: 0.4em;

    }

    @include breakpoint(phone){
        .slick-slide{
            text-align: left;
            padding-left: 65px;
            // padding-right: 10px;
        }
        .icon{
            margin: 0;
            display: block;
            position: absolute;
            // left: 10px;
        }


        .slick-slide{
            padding-right: 20px;
        }
        .title{
            font-size: 20px;

        }
    }

}



.slick-dots{
    text-align: center;
    list-style: none;
    margin: 2em 0 0;
    line-height: 1;

    li{
        border: 1px solid $color-pink;
        width: 9px;
        height: 9px;
        border-radius: 10px;
        display: inline-block;
        margin: 3px;

        &.slick-active{
            background: $color-pink;
        }
    }
    button{
        width: 9px;
        height: 9px;
        opacity: 0;
    }
}
.dots-left .slick-dots{
    text-align: left;
}
