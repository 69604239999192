
[type='checkbox']{
    display: none;
}
[type='checkbox'] + label{
    position: relative;
    margin-left: 0;
    padding-left: 25px;
    &:before{
        content: '';
        display: inline-block;
        border-radius: $global-radius;
        border: $input-border;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        vertical-align: baseline;
        top: 5px;
        left: 0;

        position: absolute;
    }
    &:after{
        position: absolute;
        top: 6px;
        left: 3px;
        content: '';
        display: block;
        height: 7px;
        width: 13px;
        transform: rotate(-45deg);
        border: $input-border;
        border-width: 2px;
        border-top: 0;
        border-right: 0;
        opacity: 0;


    }
}

[type='checkbox']:checked + label{
    &:before{
        border-color: $primary-color;
    }
    &:after{
        border-color: $primary-color;
        opacity: 1;
    }

}


[type='checkbox'].error + label{
    &:before{
        border-color: red;
        animation: chPulse 333ms infinite;
    }
    &:after{
        border-color: red;
    }

}
@keyframes chPulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}





[type='radio']{
    display: none;
}
[type='radio'] + label{
    position: relative;
    margin-left: 0;
    &:before{
        content: '';
        display: inline-block;
        border-radius: 20px;
        border: $input-border;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        vertical-align: baseline;
        top: 2px;
        position: relative;
    }

}

[type='radio']:checked + label{
    &:before{
        border-color: $primary-color;
        // box-shadow: inset $primary-color 0px 0px 4px 1px;
    }
    &:after{
        content: '';
        display: inline-block;
        background: $primary-color;
        border-radius: 20px;
        position: absolute;
        top: 7px;
        left: 5px;
        width: 5px;
        height: 5px;
    }
}
