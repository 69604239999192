@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.shake-animation {
  animation: shake 1s;
}

.smack-target {
  animation: smack 750ms linear both;
}
.smack-target-on-hover:hover {
  animation: smack 750ms linear both;
}

/* Generated with Bounce.js. Edit at http://goo.gl/eeFpmY */

@keyframes smack {
  0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.2% { transform: matrix3d(1.035, 0.096, 0, 0, 0.096, 1.035, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.5% { transform: matrix3d(1.049, 0.114, 0, 0, 0.114, 1.049, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.41% { transform: matrix3d(1.067, 0.124, 0, 0, 0.124, 1.067, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.01% { transform: matrix3d(1.088, 0.115, 0, 0, 0.115, 1.088, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  12.71% { transform: matrix3d(1.106, 0.082, 0, 0, 0.082, 1.106, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  13.51% { transform: matrix3d(1.109, 0.074, 0, 0, 0.074, 1.109, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.92% { transform: matrix3d(1.114, 0.034, 0, 0, 0.034, 1.114, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.92% { transform: matrix3d(1.114, 0.027, 0, 0, 0.027, 1.114, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.23% { transform: matrix3d(1.108, -0.001, 0, 0, -0.001, 1.108, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.03% { transform: matrix3d(1.103, -0.005, 0, 0, -0.005, 1.103, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  31.43% { transform: matrix3d(1.101, -0.006, 0, 0, -0.006, 1.101, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  34.63% { transform: matrix3d(1.099, -0.005, 0, 0, -0.005, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  40.14% { transform: matrix3d(1.098, -0.003, 0, 0, -0.003, 1.098, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  56.46% { transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  62.36% { transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  81.48% { transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  84.68% { transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}


.drift-target {
  animation: drift 1ms infinite linear ;
}

@keyframes drift {
	0% { transform: translateY(0); }
	50% { transform: translateY(10px); }

	100% { transform:  translateY(0); }
}


@keyframes shake-slow {
  2% {
    transform: translate(0px, 4px) rotate(-0.5deg); }
  4% {
    transform: translate(-3px, 9px) rotate(-0.5deg); }
  6% {
    transform: translate(4px, 10px) rotate(-0.5deg); }
  8% {
    transform: translate(0px, -2px) rotate(0.5deg); }
  10% {
    transform: translate(9px, -9px) rotate(1.5deg); }
  12% {
    transform: translate(-9px, 9px) rotate(1.5deg); }
  14% {
    transform: translate(10px, -1px) rotate(-0.5deg); }
  16% {
    transform: translate(7px, -1px) rotate(0.5deg); }
  18% {
    transform: translate(-8px, 8px) rotate(1.5deg); }
  20% {
    transform: translate(1px, -9px) rotate(0.5deg); }
  22% {
    transform: translate(7px, -6px) rotate(0.5deg); }
  24% {
    transform: translate(-8px, -4px) rotate(-1.5deg); }
  26% {
    transform: translate(-9px, 5px) rotate(-1.5deg); }
  28% {
    transform: translate(7px, 3px) rotate(-1.5deg); }
  30% {
    transform: translate(9px, 3px) rotate(2.5deg); }
  32% {
    transform: translate(2px, -6px) rotate(-1.5deg); }
  34% {
    transform: translate(10px, -7px) rotate(2.5deg); }
  36% {
    transform: translate(-2px, 10px) rotate(-2.5deg); }
  38% {
    transform: translate(-6px, -8px) rotate(-1.5deg); }
  40% {
    transform: translate(1px, 0px) rotate(-2.5deg); }
  42% {
    transform: translate(10px, 8px) rotate(3.5deg); }
  44% {
    transform: translate(4px, 2px) rotate(2.5deg); }
  46% {
    transform: translate(10px, -2px) rotate(-1.5deg); }
  48% {
    transform: translate(10px, 0px) rotate(-2.5deg); }
  50% {
    transform: translate(-2px, 5px) rotate(-0.5deg); }
  52% {
    transform: translate(4px, 1px) rotate(3.5deg); }
  54% {
    transform: translate(-8px, -5px) rotate(2.5deg); }
  56% {
    transform: translate(8px, -8px) rotate(2.5deg); }
  58% {
    transform: translate(-3px, -6px) rotate(1.5deg); }
  60% {
    transform: translate(-3px, -5px) rotate(0.5deg); }
  62% {
    transform: translate(-1px, 0px) rotate(-0.5deg); }
  64% {
    transform: translate(2px, 10px) rotate(3.5deg); }
  66% {
    transform: translate(-4px, -1px) rotate(-0.5deg); }
  68% {
    transform: translate(8px, -5px) rotate(1.5deg); }
  70% {
    transform: translate(-3px, 2px) rotate(-1.5deg); }
  72% {
    transform: translate(9px, -3px) rotate(-0.5deg); }
  74% {
    transform: translate(-2px, 6px) rotate(1.5deg); }
  76% {
    transform: translate(-7px, -8px) rotate(-1.5deg); }
  78% {
    transform: translate(7px, -4px) rotate(0.5deg); }
  80% {
    transform: translate(-4px, -5px) rotate(-1.5deg); }
  82% {
    transform: translate(8px, 6px) rotate(3.5deg); }
  84% {
    transform: translate(0px, -7px) rotate(-1.5deg); }
  86% {
    transform: translate(0px, 1px) rotate(3.5deg); }
  88% {
    transform: translate(-6px, -3px) rotate(2.5deg); }
  90% {
    transform: translate(1px, 3px) rotate(-0.5deg); }
  92% {
    transform: translate(-9px, -4px) rotate(-2.5deg); }
  94% {
    transform: translate(-7px, -6px) rotate(2.5deg); }
  96% {
    transform: translate(5px, -3px) rotate(-1.5deg); }
  98% {
    transform: translate(8px, 4px) rotate(0.5deg); }
  0%, 100% {
    transform: translate(0, 0) rotate(0); } }





.plump-target {
  animation: plump 1000ms linear both;
}

/* Generated with Bounce.js. Edit at http://goo.gl/y3FSYm */

@keyframes plump {


      0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      3.4% { transform: matrix3d(1.063, 0, 0, 0, 0, 1.081, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      4.7% { transform: matrix3d(1.09, 0, 0, 0, 0, 1.12, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      6.81% { transform: matrix3d(1.132, 0, 0, 0, 0, 1.179, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      9.41% { transform: matrix3d(1.177, 0, 0, 0, 0, 1.234, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      10.21% { transform: matrix3d(1.188, 0, 0, 0, 0, 1.245, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      13.61% { transform: matrix3d(1.225, 0, 0, 0, 0, 1.266, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      14.11% { transform: matrix3d(1.228, 0, 0, 0, 0, 1.266, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      17.52% { transform: matrix3d(1.242, 0, 0, 0, 0, 1.248, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      18.72% { transform: matrix3d(1.242, 0, 0, 0, 0, 1.237, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      21.32% { transform: matrix3d(1.239, 0, 0, 0, 0, 1.214, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      24.32% { transform: matrix3d(1.23, 0, 0, 0, 0, 1.192, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      25.23% { transform: matrix3d(1.227, 0, 0, 0, 0, 1.188, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      29.03% { transform: matrix3d(1.213, 0, 0, 0, 0, 1.179, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      29.93% { transform: matrix3d(1.21, 0, 0, 0, 0, 1.18, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      35.54% { transform: matrix3d(1.196, 0, 0, 0, 0, 1.192, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      36.74% { transform: matrix3d(1.194, 0, 0, 0, 0, 1.196, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      41.04% { transform: matrix3d(1.192, 0, 0, 0, 0, 1.204, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      44.44% { transform: matrix3d(1.193, 0, 0, 0, 0, 1.206, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      52.15% { transform: matrix3d(1.198, 0, 0, 0, 0, 1.201, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      59.86% { transform: matrix3d(1.201, 0, 0, 0, 0, 1.198, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      63.26% { transform: matrix3d(1.201, 0, 0, 0, 0, 1.198, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      75.28% { transform: matrix3d(1.2, 0, 0, 0, 0, 1.201, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      85.49% { transform: matrix3d(1.2, 0, 0, 0, 0, 1.2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      90.69% { transform: matrix3d(1.2, 0, 0, 0, 0, 1.2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
      100% { transform: matrix3d(1.2, 0, 0, 0, 0, 1.2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }


}





.plum100p-target {
  animation: plum100p 1000ms linear both;
}

/* Generated with Bounce.js. Edit at http://goo.gl/Vn2Euz */

@keyframes plum100p {
  0% { transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.4% { transform: matrix3d(0.658, 0, 0, 0, 0, 0.703, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { transform: matrix3d(0.725, 0, 0, 0, 0, 0.8, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.81% { transform: matrix3d(0.83, 0, 0, 0, 0, 0.946, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { transform: matrix3d(0.942, 0, 0, 0, 0, 1.084, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  10.21% { transform: matrix3d(0.971, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  13.61% { transform: matrix3d(1.062, 0, 0, 0, 0, 1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { transform: matrix3d(1.07, 0, 0, 0, 0, 1.165, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.52% { transform: matrix3d(1.104, 0, 0, 0, 0, 1.12, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { transform: matrix3d(1.106, 0, 0, 0, 0, 1.094, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  21.32% { transform: matrix3d(1.098, 0, 0, 0, 0, 1.035, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { transform: matrix3d(1.075, 0, 0, 0, 0, 0.98, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.23% { transform: matrix3d(1.067, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.03% { transform: matrix3d(1.031, 0, 0, 0, 0, 0.948, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { transform: matrix3d(1.024, 0, 0, 0, 0, 0.949, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { transform: matrix3d(0.99, 0, 0, 0, 0, 0.981, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  36.74% { transform: matrix3d(0.986, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { transform: matrix3d(0.98, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  44.44% { transform: matrix3d(0.983, 0, 0, 0, 0, 1.016, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { transform: matrix3d(0.996, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  59.86% { transform: matrix3d(1.003, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { transform: matrix3d(1.004, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  75.28% { transform: matrix3d(1.001, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { transform: matrix3d(0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  90.69% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}







.smack-target {
  animation: smack 750ms linear both;
}

/* Generated with Bounce.js. Edit at http://goo.gl/51kj7G */

@keyframes smack {
  0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.2% { transform: matrix3d(1.345, 0.383, 0, 0, 0.252, 1.345, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.5% { transform: matrix3d(1.485, 0.5, 0, 0, 0.326, 1.485, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.41% { transform: matrix3d(1.673, 0.603, 0, 0, 0.393, 1.673, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.01% { transform: matrix3d(1.88, 0.616, 0, 0, 0.403, 1.88, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  12.71% { transform: matrix3d(2.064, 0.467, 0, 0, 0.308, 2.064, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  13.51% { transform: matrix3d(2.087, 0.424, 0, 0, 0.28, 2.087, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.92% { transform: matrix3d(2.139, 0.197, 0, 0, 0.131, 2.139, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.92% { transform: matrix3d(2.137, 0.155, 0, 0, 0.103, 2.137, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.23% { transform: matrix3d(2.075, -0.003, 0, 0, -0.002, 2.075, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.03% { transform: matrix3d(2.032, -0.03, 0, 0, -0.02, 2.032, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  31.43% { transform: matrix3d(2.012, -0.033, 0, 0, -0.022, 2.012, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  34.63% { transform: matrix3d(1.994, -0.029, 0, 0, -0.019, 1.994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  40.14% { transform: matrix3d(1.985, -0.015, 0, 0, -0.01, 1.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  56.46% { transform: matrix3d(2, 0.002, 0, 0, 0.001, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  62.36% { transform: matrix3d(2.002, 0.001, 0, 0, 0.001, 2.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  81.48% { transform: matrix3d(2, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  84.68% { transform: matrix3d(2, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(2, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}



.spaceInUp-target{
    animation: spaceInUp 555ms ease-out;
}
@keyframes spaceInUp {
  0% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(.2) translate(0%, -200%);
  }

  100% {
    opacity: 1;
    transform-origin: 50% 0%;
    transform: scale(1) translate(0%, 0%);
  }
}






.splat-target {
  animation: splat 865ms linear both;
}

/* Generated with Bounce.js. Edit at http://goo.gl/et2XBk */

@keyframes splat {
  0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -300, 0, 0, 1); }
  2.92% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -135.218, 0, 0, 1); }
  3.37% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -114.871, 0, 0, 1); }
  3.47% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -110.596, 0, 0, 1); }
  4.58% { transform: matrix3d(2.061, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -68.65, 0, 0, 1); }
  5.69% { transform: matrix3d(2.321, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -36.551, 0, 0, 1); }
  5.76% { transform: matrix3d(2.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -34.768, 0, 0, 1); }
  7.41% { transform: matrix3d(1.99, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -3.804, 0, 0, 1); }
  7.51% { transform: matrix3d(1.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -2.454, 0, 0, 1); }
  7.88% { transform: matrix3d(1.771, 0, 0, 0, 0, 1.062, 0, 0, 0, 0, 1, 0, 2.008, 0, 0, 1); }
  8.68% { transform: matrix3d(1.408, 0, 0, 0, 0, 1.181, 0, 0, 0, 0, 1, 0, 9.646, 0, 0, 1); }
  10.03% { transform: matrix3d(0.982, 0, 0, 0, 0, 1.333, 0, 0, 0, 0, 1, 0, 16.853, 0, 0, 1); }
  10.85% { transform: matrix3d(0.822, 0, 0, 0, 0, 1.398, 0, 0, 0, 0, 1, 0, 18.613, 0, 0, 1); }
  11.53% { transform: matrix3d(0.732, 0, 0, 0, 0, 1.439, 0, 0, 0, 0, 1, 0, 18.992, 0, 0, 1); }
  12.22% { transform: matrix3d(0.672, 0, 0, 0, 0, 1.469, 0, 0, 0, 0, 1, 0, 18.618, 0, 0, 1); }
  14.18% { transform: matrix3d(0.612, 0, 0, 0, 0, 1.501, 0, 0, 0, 0, 1, 0, 15.054, 0, 0, 1); }
  14.37% { transform: matrix3d(0.612, 0, 0, 0, 0, 1.501, 0, 0, 0, 0, 1, 0, 14.604, 0, 0, 1); }
  19.23% { transform: matrix3d(0.737, 0, 0, 0, 0, 1.371, 0, 0, 0, 0, 1, 0, 3.855, 0, 0, 1); }
  20.01% { transform: matrix3d(0.763, 0, 0, 0, 0, 1.338, 0, 0, 0, 0, 1, 0, 2.724, 0, 0, 1); }
  23.05% { transform: matrix3d(0.856, 0, 0, 0, 0, 1.211, 0, 0, 0, 0, 1, 0, 0.036, 0, 0, 1); }
  25.75% { transform: matrix3d(0.923, 0, 0, 0, 0, 1.114, 0, 0, 0, 0, 1, 0, -0.709, 0, 0, 1); }
  26.94% { transform: matrix3d(0.947, 0, 0, 0, 0, 1.078, 0, 0, 0, 0, 1, 0, -0.76, 0, 0, 1); }
  31.58% { transform: matrix3d(1.009, 0, 0, 0, 0, 0.987, 0, 0, 0, 0, 1, 0, -0.406, 0, 0, 1); }
  31.73% { transform: matrix3d(1.01, 0, 0, 0, 0, 0.986, 0, 0, 0, 0, 1, 0, -0.392, 0, 0, 1); }
  37.32% { transform: matrix3d(1.029, 0, 0, 0, 0, 0.958, 0, 0, 0, 0, 1, 0, -0.03, 0, 0, 1); }
  38.15% { transform: matrix3d(1.029, 0, 0, 0, 0, 0.958, 0, 0, 0, 0, 1, 0, -0.008, 0, 0, 1); }
  42.35% { transform: matrix3d(1.022, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0.03, 0, 0, 1); }
  48.9% { transform: matrix3d(1.007, 0, 0, 0, 0, 0.99, 0, 0, 0, 0, 1, 0, 0.009, 0, 0, 1); }
  57.77% { transform: matrix3d(0.998, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, -0.001, 0, 0, 1); }
  60.47% { transform: matrix3d(0.998, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, -0.001, 0, 0, 1); }
  69.36% { transform: matrix3d(0.999, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  83.61% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}







.splatTop-target {
  animation: splatTop 865ms linear both;
}

/* Generated with Bounce.js. Edit at http://goo.gl/DBwMfh */

@keyframes splatTop {
  0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -300, 0, 1); }
  2.92% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -135.218, 0, 1); }
  3.37% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -114.871, 0, 1); }
  3.47% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -110.596, 0, 1); }
  4.58% { transform: matrix3d(1, 0, 0, 0, 0, 2.061, 0, 0, 0, 0, 1, 0, 0, -68.65, 0, 1); }
  5.69% { transform: matrix3d(1, 0, 0, 0, 0, 2.321, 0, 0, 0, 0, 1, 0, 0, -36.551, 0, 1); }
  5.76% { transform: matrix3d(1, 0, 0, 0, 0, 2.32, 0, 0, 0, 0, 1, 0, 0, -34.768, 0, 1); }
  7.41% { transform: matrix3d(1, 0, 0, 0, 0, 1.99, 0, 0, 0, 0, 1, 0, 0, -3.804, 0, 1); }
  7.51% { transform: matrix3d(1, 0, 0, 0, 0, 1.961, 0, 0, 0, 0, 1, 0, 0, -2.454, 0, 1); }
  7.88% { transform: matrix3d(1.062, 0, 0, 0, 0, 1.771, 0, 0, 0, 0, 1, 0, 0, 2.008, 0, 1); }
  8.68% { transform: matrix3d(1.181, 0, 0, 0, 0, 1.408, 0, 0, 0, 0, 1, 0, 0, 9.646, 0, 1); }
  10.03% { transform: matrix3d(1.333, 0, 0, 0, 0, 0.982, 0, 0, 0, 0, 1, 0, 0, 16.853, 0, 1); }
  10.85% { transform: matrix3d(1.398, 0, 0, 0, 0, 0.822, 0, 0, 0, 0, 1, 0, 0, 18.613, 0, 1); }
  11.53% { transform: matrix3d(1.439, 0, 0, 0, 0, 0.732, 0, 0, 0, 0, 1, 0, 0, 18.992, 0, 1); }
  12.22% { transform: matrix3d(1.469, 0, 0, 0, 0, 0.672, 0, 0, 0, 0, 1, 0, 0, 18.618, 0, 1); }
  14.18% { transform: matrix3d(1.501, 0, 0, 0, 0, 0.612, 0, 0, 0, 0, 1, 0, 0, 15.054, 0, 1); }
  14.37% { transform: matrix3d(1.501, 0, 0, 0, 0, 0.612, 0, 0, 0, 0, 1, 0, 0, 14.604, 0, 1); }
  19.23% { transform: matrix3d(1.371, 0, 0, 0, 0, 0.737, 0, 0, 0, 0, 1, 0, 0, 3.855, 0, 1); }
  20.01% { transform: matrix3d(1.338, 0, 0, 0, 0, 0.763, 0, 0, 0, 0, 1, 0, 0, 2.724, 0, 1); }
  23.05% { transform: matrix3d(1.211, 0, 0, 0, 0, 0.856, 0, 0, 0, 0, 1, 0, 0, 0.036, 0, 1); }
  25.75% { transform: matrix3d(1.114, 0, 0, 0, 0, 0.923, 0, 0, 0, 0, 1, 0, 0, -0.709, 0, 1); }
  26.94% { transform: matrix3d(1.078, 0, 0, 0, 0, 0.947, 0, 0, 0, 0, 1, 0, 0, -0.76, 0, 1); }
  31.58% { transform: matrix3d(0.987, 0, 0, 0, 0, 1.009, 0, 0, 0, 0, 1, 0, 0, -0.406, 0, 1); }
  31.73% { transform: matrix3d(0.986, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, -0.392, 0, 1); }
  37.32% { transform: matrix3d(0.958, 0, 0, 0, 0, 1.029, 0, 0, 0, 0, 1, 0, 0, -0.03, 0, 1); }
  38.15% { transform: matrix3d(0.958, 0, 0, 0, 0, 1.029, 0, 0, 0, 0, 1, 0, 0, -0.008, 0, 1); }
  42.35% { transform: matrix3d(0.969, 0, 0, 0, 0, 1.022, 0, 0, 0, 0, 1, 0, 0, 0.03, 0, 1); }
  48.9% { transform: matrix3d(0.99, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0.009, 0, 1); }
  57.77% { transform: matrix3d(1.003, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, -0.001, 0, 1); }
  60.47% { transform: matrix3d(1.004, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, -0.001, 0, 1); }
  69.36% { transform: matrix3d(1.001, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  83.61% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}




.slideInRight-target {
  animation: slideInRight 555ms ease-out both;
}

/* animate.css */
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRightOp {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInDownOp-target {
  animation: slideInDownOp 555ms ease-out both;
}
@keyframes slideInDownOp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
    opacity: 0;
  }

  to {

    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}



.rubberBand-target {
  animation: rubberBand 555ms ease-out both;
}
@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}





.bounceInUp-target {
  animation: bounceInUp 555ms ease-out both;
}

.bounceInUp-icon-target:hover .icon {
  animation: bounceInUp 555ms ease-out both;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}






@keyframes shake-slow {
  2% {
    transform: translate(6px, -5px) rotate(2.5deg); }
  4% {
    transform: translate(10px, -6px) rotate(2.5deg); }
  6% {
    transform: translate(-2px, -2px) rotate(-2.5deg); }
  8% {
    transform: translate(6px, -3px) rotate(-1.5deg); }
  10% {
    transform: translate(8px, 1px) rotate(-2.5deg); }
  12% {
    transform: translate(2px, -2px) rotate(-1.5deg); }
  14% {
    transform: translate(-4px, 7px) rotate(-1.5deg); }
  16% {
    transform: translate(-9px, 9px) rotate(-0.5deg); }
  18% {
    transform: translate(8px, 9px) rotate(-0.5deg); }
  20% {
    transform: translate(9px, -8px) rotate(-0.5deg); }
  22% {
    transform: translate(3px, -8px) rotate(3.5deg); }
  24% {
    transform: translate(0px, 0px) rotate(1.5deg); }
  26% {
    transform: translate(10px, -7px) rotate(1.5deg); }
  28% {
    transform: translate(3px, 6px) rotate(2.5deg); }
  30% {
    transform: translate(-3px, 3px) rotate(0.5deg); }
  32% {
    transform: translate(-4px, 9px) rotate(0.5deg); }
  34% {
    transform: translate(10px, 0px) rotate(1.5deg); }
  36% {
    transform: translate(-4px, 9px) rotate(0.5deg); }
  38% {
    transform: translate(-1px, 10px) rotate(-0.5deg); }
  40% {
    transform: translate(3px, -3px) rotate(2.5deg); }
  42% {
    transform: translate(-2px, 5px) rotate(3.5deg); }
  44% {
    transform: translate(7px, -2px) rotate(-0.5deg); }
  46% {
    transform: translate(5px, 4px) rotate(-2.5deg); }
  48% {
    transform: translate(-9px, 8px) rotate(2.5deg); }
  50% {
    transform: translate(-3px, -7px) rotate(-1.5deg); }
  52% {
    transform: translate(-1px, 1px) rotate(-2.5deg); }
  54% {
    transform: translate(-3px, -7px) rotate(-2.5deg); }
  56% {
    transform: translate(9px, -5px) rotate(3.5deg); }
  58% {
    transform: translate(-6px, 4px) rotate(-2.5deg); }
  60% {
    transform: translate(4px, 5px) rotate(-0.5deg); }
  62% {
    transform: translate(5px, -9px) rotate(0.5deg); }
  64% {
    transform: translate(-4px, -4px) rotate(1.5deg); }
  66% {
    transform: translate(1px, -8px) rotate(0.5deg); }
  68% {
    transform: translate(-6px, -7px) rotate(1.5deg); }
  70% {
    transform: translate(-5px, 4px) rotate(-2.5deg); }
  72% {
    transform: translate(5px, -3px) rotate(0.5deg); }
  74% {
    transform: translate(7px, 6px) rotate(1.5deg); }
  76% {
    transform: translate(-5px, 6px) rotate(-1.5deg); }
  78% {
    transform: translate(10px, 6px) rotate(3.5deg); }
  80% {
    transform: translate(3px, 10px) rotate(-1.5deg); }
  82% {
    transform: translate(-2px, 5px) rotate(3.5deg); }
  84% {
    transform: translate(8px, -1px) rotate(-0.5deg); }
  86% {
    transform: translate(-6px, 5px) rotate(3.5deg); }
  88% {
    transform: translate(5px, 5px) rotate(0.5deg); }
  90% {
    transform: translate(2px, -3px) rotate(-0.5deg); }
  92% {
    transform: translate(-6px, 1px) rotate(-1.5deg); }
  94% {
    transform: translate(10px, 4px) rotate(1.5deg); }
  96% {
    transform: translate(3px, 5px) rotate(1.5deg); }
  98% {
    transform: translate(7px, 1px) rotate(-2.5deg); }
  0%, 100% {
    transform: translate(0, 0) rotate(0); } }

.shake-slow {
  animation: shake-slow 5s ease-in-out infinite;
}
.css-shake-long{
  animation: shake-slow 14s ease-in-out infinite;
}
.css-shake-long2{
    animation: shake-slow 24s ease-in-out infinite;
    transform: rotate(130deg);
}






.zoomIn-target-onHover:hover {
  animation: zoomIn 555ms linear both;
}


.zoomIn-target {
  animation: zoomIn 1000ms linear both;
}

/* Generated with Bounce.js. Edit at http://goo.gl/nEY3Yv */

@keyframes zoomIn {
  0% { transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.4% { transform: matrix3d(0.932, 0, 0, 0, 0, 0.941, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { transform: matrix3d(0.945, 0, 0, 0, 0, 0.96, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.81% { transform: matrix3d(0.966, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { transform: matrix3d(0.988, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  10.21% { transform: matrix3d(0.994, 0, 0, 0, 0, 1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  13.61% { transform: matrix3d(1.012, 0, 0, 0, 0, 1.033, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { transform: matrix3d(1.014, 0, 0, 0, 0, 1.033, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.52% { transform: matrix3d(1.021, 0, 0, 0, 0, 1.024, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { transform: matrix3d(1.021, 0, 0, 0, 0, 1.019, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  21.32% { transform: matrix3d(1.02, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { transform: matrix3d(1.015, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.23% { transform: matrix3d(1.013, 0, 0, 0, 0, 0.994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.03% { transform: matrix3d(1.006, 0, 0, 0, 0, 0.99, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { transform: matrix3d(1.005, 0, 0, 0, 0, 0.99, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { transform: matrix3d(0.998, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  36.74% { transform: matrix3d(0.997, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { transform: matrix3d(0.996, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  44.44% { transform: matrix3d(0.997, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { transform: matrix3d(0.999, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  59.86% { transform: matrix3d(1.001, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { transform: matrix3d(1.001, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  75.28% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  90.69% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}




.frogBounce-target {
  animation: frogBounce 1333ms linear infinite both;
}

/* Generated with Bounce.js. Edit at http://goo.gl/KgDWHs */

@keyframes frogBounce {

	0% {
		transform: translateY(0%) scaleY(1);
	}
	5%{
		transform: translateY(-100%) scaleY(1.1);
	}
	10%{
		transform: translateY(0%) scaleY(0.95) scaleX(1.25);
	}
	15%{
		transform: translateY(0%) scaleY(1.05) scaleX(1);
	}
	20%{
		transform: translateY(0%) scaleY(0.95) scaleX(1);
	}
	25%,100%{
		transform: translateY(0%) scaleY(1) scaleX(1);
	}


}
