
.gallery-slider{

	position: absolute;
    left: 0;
    right: 0;
    z-index: 11;

	background: $color_blue;
    // box-shadow: rgba(0,0,0,.2) 0 3px 15px 0;

    transition: top 222ms ease-in-out;


    padding: 50px 0 45px;

	button.close{
		background: transparent;
		position: absolute;
		top: 20px;
		right: 20px;
		svg{
			width: 25px;
			height: 25px;
			fill: #fff;
		}
	}


    .arrow {
    	bottom: 100%;
    	left: 50%;
    	border: solid transparent;
    	content: " ";
    	height: 0;
    	width: 0;
    	position: absolute;
    	pointer-events: none;
    	border-color: rgba(204, 204, 204, 0);
    	border-bottom-color: $color_blue;
    	border-width: 15px;
    	margin-left: -15px;

        transform: translateY(0px);

        transition: left 333ms ease-in-out, transform 222ms 222ms;


    }


	.slick-slide{
		height: 300px;
		img{
			display: block;
			max-width: 100%;
			max-height: 300px;
			margin: auto;

			position: relative;
			top: 50%;
			transform: translateY(-50%);

		}
	}

    &_nav{
        margin-top: 1em;
		position: relative;
		font-size: 14px;
		
		.arrow-prev,
		.arrow-next{
			font-size: 20px;
			margin-top: -0.1em;
			cursor: pointer;
			display: inline-block;
		}

		.aside{
			float: right;
			position: absolute;
			top: 0;
			right: 10px;
		}
		.download{
			width: 20px;
			height: 20px;
			fill: #fff;
			cursor: pointer;
		}
    }




    &.active{
        // box-shadow: rgba(0,0,0,.3) 0 10px 10px 0;
        .arrow{
            transform: translateY(0);
        }
        .content{
            opacity: 1;
        }
    }


	@include breakpoint(medium){

			.slick-slide{
				min-height: 500px;
				img{

					max-height: 500px;

				}
			}
	}

}
