.gallery-container{

}

.month-select{
    margin: 0 0 2em;
    overflow: hidden;

    @extend .lato;

    .year-select,
    .months a{
        display: block;
        float: left;
        height: 35px;
        line-height: 33px;
        text-align: center;

        color: #888;
        cursor: pointer;


        border: 1px solid #eee;
        border-left: 0;
        border-radius: 0;


        text-align: center;
        overflow: hidden;

        transition: 333ms;




    }
    .year-select{
        width: 15%;
        border-color: $color_pink;
        background: $color_pink;
        color: #fff;
    }

    .dropdown-pane{
        width: 150px;
        border-radius: 0;
        border-color: #eee;
        box-shadow: rgba(0,0,0,.2) 2px 2px 5px 0;
        padding: 0;
        a{
            display: block;
            padding: 0.5em;
            color: $color_blue;
            border-bottom: 1px solid #eee;
            text-align: center;
            transition:
            background 333ms,
            color 333ms;
            &:hover{
                background: $color_pink;
                color: #fff;
            }
            &:last-child{
                border-bottom: 0;
            }
        }
    }

    .months a{
        width: ( (100% - 15%) / 12 );
        background: #fff;
        &:hover{
            background: rgba($color_pink,.1);
            border-top-color: rgba($color_pink,.1);
            border-bottom-color: rgba($color_pink,.1);
            color: #fff;
        }
        &.active{
            background: $color_pink;
            border-top-color:$color_pink;
            border-bottom-color:$color_pink;
            color: #fff;
        }
    }



    @include breakpoint(large){
        .year-select{
            width: 10%;

        }
        .months a{
            width: ( (100% - 10%) / 12 );

        }
    }
}


.gallery-item{


    padding: 10px;
    // clear: none !important;

    animation: slideInRightOp 1s ;
    animation-fill-mode: both;



    @for $i from 1 through 8 {
        &.g-#{$i}{ animation-delay: ( $i * 88ms );  }
    }


    &_inside{
        position: relative;


    }
    &_bottom{
        border: 1px solid #eee;
        padding: 0.2em 0.2em 0.8em;
    }

    &.active &_image{
        box-shadow: inset $color_blue  0 0 0 4px;
    }

    &.active{
        margin-bottom: 460px;
        transition: margin 222ms;
    }


        &_image{
            padding-bottom: 65%;
            background-position: center;
            background-size: cover;

        }

        &_apla{
            background: rgba($color_blue, .8);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            transform: scale(.8);
            transition: 333ms;
            display: none;

            nav{
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
                text-align: center;
                font-size: 0;
                a{
                    border: 1px solid #fff;
                    border-radius: 40px;
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    margin: 0 4%;
                    transition: 333ms;

                    svg{
                        margin: 25%;
                        width: 50%;
                        height: 50%;
                        display: block;
                    }

                    &:hover{
                        background: $color_pink;
                        box-shadow: #fff 0 0 0 2px;
                    }
                }
            }

        }

    &_title{
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #000;
        padding: 0.5em 0 0;
    }
    &_date{
        color: #555;
        text-align: center;
        font-size: 14px;
         @extend .lato;
    }




    @include breakpoint(medium){
        &_apla{
            display: block;
        }
        &.active{
            margin-bottom: 660px;

        }

        &_inside:hover &_apla{
            opacity: 1;
            transform: scale(1);
        }
    }
}



.pagination{
    @extend .lato;

    text-align: center;
    margin: 2em 0 1em;

    ul{
        list-style: none;
        padding: 0;
        margin: 0;
    }
    li{
        color: #888;
        display: inline-block;
        border: 1px solid #eee;
        border-radius: 0;
        width: 35px;
        height: 35px;
        vertical-align: middle;
        line-height: 33px;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        &:hover{
            border-color: $color_pink;
        }
    }
    li.MarkupPagerNavOn,
    li.active{
        background: $color_pink;
        color: #fff;
    }

    a{
        border-radius: 0;
        background: inherit;
        color: inherit;
        display: inline-block;
        width: inherit;
        height: inherit;
        padding: 0;
        margin: 0;

        &:hover{
            background: transparent;
        }

    }


    @include breakpoint(large){
        li{
            margin: 0 2px;
        }
    }

}
