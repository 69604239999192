
html, body{
	font-family: Lora, Arial, sans-serif;
}
a{
	color: $color_blue;
}
ol,
ul{
	list-style-position: inside;
}

.bgcolor-blue a{
	color: $color_pink;
}

h2,h3,h4{
	color: $color_pink;
	margin-bottom: 1em;
	.bgcolor-pink &,
	.bgcolor-blue &{
		color: #fff;
	}
}

h1{
	color: $color_pink;
	margin-bottom: .8em;
	.bgcolor-blue &{
		color: #fff;
	}
}

body{
	background: #fff;
	font-size: 12px;
	color: #062033;

}

hr,
.heart-separator{
	background: url(../img/heart-separator.png) center top no-repeat;
	display: block;
	height: 18px;
	margin: 2em 0;
	border:0;
	max-width: none;

	&:first-child{
		margin-top: 0;
	}
}

.svg-sprite{
	height: 0;
	overflow: hidden;
}
.container{
	position: relative;
}
.container-paddings{
	padding-top: 70px;
	padding-bottom: 50px;
}

.container-paddings-2x{
	padding-top: (100px/2);
	padding-bottom:  (80px/2);
}
.container-paddings-title{
	padding-top: 80px;
	padding-bottom: 0px;
}

.googlemap{
	height: 300px;
}


.pict{
	display: block;
	max-width: 20%;
	margin: 0 auto 20px;
}


@include breakpoint(medium){
	.container-paddings-title{
		padding-top: 140px;
		padding-bottom: 20px;
	}

	.container-paddings-2x{
		padding-top: 100px;
		padding-bottom: 80px;
	}
}



.address{
	a{
		color: inherit;
		&:hover{
			color: $color_pink;
		}
	}
	svg{
		width: 1em;
		height: 1em;
		display: inline-block;
		fill: $color_pink;
		color: $color_pink;
		vertical-align: -0.05em;
		margin-right: 0.3em;
	}
}


.kitty-svg{
	max-width: 60%;
	margin: 0 auto -50px;
	overflow: hidden;
}

.frog-svg{
	bottom: 0px;
	max-width: 25%;
	right: 10%;
	position: absolute;
}
.kids-big{
	bottom: -10px;
	max-width: 20%;
	right: 2%;
	position: absolute;
}

.lettering > span{

}
// .lettering > span:hover{
// 	transform: scale(2);
// 	//  animation: smack 750ms linear both;
// }

@include breakpoint(medium){

	.frog-svg{
		max-width: 50%;
	}
	.kitty-svg{
		position: absolute;
		max-width: 100%;
		bottom: -10px;
		margin-bottom: 0;
		// text-shadow: #000 0 0 20px;
	}
}



.contact-column{
	margin-bottom: 30px;
}

.kids-promo{
	background: url(../img/kids-transparent.png) bottom center no-repeat;
	background-size:  130% auto;

	padding-bottom: 50%;
	padding-top: 100px;

	.text{
		color: $color_blue;
		margin: auto;
		max-width: 90%;
	}
	p{
		margin-left: auto;
		margin-right: auto;
		max-width: 70%;
		font-size: 14px;
	}

	h1{
		color: $color_pink;
		font-size: 30px;
		margin-bottom: .7em;
	}
	h3{
		font-size: 18px;
		font-weight: bold;
	}
	.button{
		margin-top: 1em;
	}


	@include breakpoint(medium down){
		.button{

			font-size: 14px;
		}
	}

	@include breakpoint(medium){

		background-size:  100% auto;
		background-position: bottom center;
		padding-bottom: 10%;
		padding-top: 13%;

		min-height: 100vh;

		.text{
			max-width: 44%;
		}

		p{
			font-size: 16px;
			max-width: 80%;
		}

		h1{
			font-size: 40px;
		}
		h3{
			font-size: 24px;
		}


	}

	@include breakpoint(large){

		h1{
			font-size: 50px;
		}
	}
	@include breakpoint(xxlarge){

		h1{
			font-size: 65px;
		}
	}
}


.grupa-icon{
	.icon{
		transition: 222ms;
		box-shadow: inset #fff 0 0 2px 0px, #fff 0 0 0 0px;
		overflow: hidden;
		border-radius: 200px;
		&:hover{
			box-shadow: inset #2E73B7 0 0 2px 10px, #09F 0 0 0 10px;
		}
	}
}


.gallery-pict-family{
	padding: 20px 0;
	img{
		width: 30px;
	}
	@include breakpoint(phone){
		padding: 40px 0;
		img{ width: auto;		}
	}
}


.footer nav > ul{
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
	font-family: Lato;
	font-size: 11px;
	text-transform: uppercase;

	ul{
		list-style: none;
		margin: 0;
		padding: 0;
	}

	> li > a{
		font-weight: bold;
	}

	> li{
		display: block;
		margin-bottom: 1em;
		a{
			color: #fff;
			display: inline-block;
			padding: 0 .8em;
		}
		> ul > li{
			display: inline-block;
		}
	}

	@include breakpoint(large){
		> li{
			display: inline-block;
			margin-bottom: 0;
		}
		> li > ul{ display: inline-block; }
	}
}



.bg-white-icons{
	background: #fff url(../img/bg-icons.png) top left ;
	background-attachment: fixed;
}


::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #999;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #999;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #999;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #999;
}





/* mobile */

@include breakpoint(small){
	// .row{
	// 	max-width: 500px;
	// }
}

@include breakpoint(medium){

	body{
		font-size: 16px;
	}

}
@include breakpoint(large){


	.row{
		max-width: 94%;
		width: 1260px;
		&.expanded{
			width: 100%;
		}
		.row{
			max-width: none;
			width: auto;
		}
	}
}
@include breakpoint(xlarge){




	.row{
		// max-width: none;
		width: 1660px;
		.row{
			max-width: none;
			width: auto;
		}
	}
}


// @keyframes pulse{
// 	0%{
// 		opacity: 1;
// 	}
// 	30%{ opacity: .9; }
// 	100%{ opacity: 1; }
// }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animation-pulse {
  animation-name: pulse;
}
