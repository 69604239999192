
.button-group{
    .button{
        border-radius: 0;
    }
    .button:first-child{
        border-radius: $button-radius 0 0 $button-radius;
    }
    .button:last-child{
        border-radius: 0 $button-radius $button-radius 0;
    }
    &.round{

        .button:first-child{
            border-radius: $button-round-radius 0 0 $button-round-radius;
        }
        .button:last-child{
            border-radius: 0 $button-round-radius $button-round-radius 0;
        }
    }
}
