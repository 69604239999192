.photo-slider{


    &_text{
        // padding-left: 20px;
        padding-top: 20px;
    }
    .photos{
        position: relative;
        margin: 0;
        padding: 0px;
        width: auto;
    }
    .photo{

        display: block;
        width: 100%;
        opacity: 0;
        transform: scale(.9) translateY(-10%);
        transform-origin: center;
        transition: 333ms;
        position: absolute;
        top: 0;

        &:first-child{
            position: relative;
        }

        &.active{

            opacity: 1;
            transform: scale(1) translateY(0%);;
        }
    }
    .title{
        font-family: Lora;

        margin-bottom: 0.5em;
    }
    .small-title{
        margin-bottom: 0.8em;
    }

    .slick-dots{
        margin:0;
    }

    @include breakpoint(large){
        &_text{
            padding-left: 20px;
            padding-top: 0;
        }
    }

}
