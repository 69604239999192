.offer-items,
.slick-offer--on-small{

    // font-size: 12px;
    p{
        font-family: Lato, sans-serif;
        line-height: 1.25;
    }

    .column{
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        text-align: center;
    }
    .icon{
        width: 46px;
        display: block;
        position: static;
        margin: 0 auto 20px;
        left: 10px;
    }

    .title{
        // font-weight: bold;
        font-size: 16px;
        margin-bottom: 0.4em;
        font-family: Lora;

    }

    .column:hover{
        .icon{ animation: rubberBand 555ms ease-out both; }
    }

    @include breakpoint(medium){
        .column{
            text-align: left;
            padding-left: 65px;
            position: relative;
            padding-right: 20px;
            margin-bottom: 30px;
        }
        .icon{
            margin: 0;
            display: block;
            position: absolute;
            // left: 10px;
        }

        .title{
            font-size: 20px;

        }
    }




    .slick-dots{
        text-align: center;
        list-style: none;
        margin: 2em 0 0;
        line-height: 1;

        li{
            border: 1px solid #fff;
            width: 9px;
            height: 9px;
            border-radius: 10px;
            display: inline-block;
            margin: 3px;

            &.slick-active{
                background: #fff;
            }
        }
        button{
            width: 9px;
            height: 9px;
            opacity: 0;
        }
    }

}
