.plan-module{

    &_header{
        border-bottom: 1px solid rgba(255,255,255,.5);
    }

    &_item{
        border-bottom: 1px solid rgba(255,255,255,.5);
        width: 100%;
    }

        &_hour-cell,
        &_text-cell{
            display: table-cell;
            vertical-align: middle;
            float: none;
            // padding: 1.2em .5em;

        }

        &_hour-cell,
        &_text-cell{
            padding: 1em 0em;
        }

        // &_hour-cell{
        //     width: 20%;
        // }
        // &_text-cell{
        //     width: 80%;
        // }

        &_hour-cell{
            // padding-bottom: 0;
            width: 30%;
        }

        &_item{
            display: table;
        }

        p{
            margin: 0;
        }

        .expand-btn{
            fill: $color_pink;
            vertical-align: middle;
            float: right;
            > svg{
                width: 1.5em;
                height: 1.5em;
                transition: 333ms;
            }

            &:hover{

                > svg{ transform: translateY(5px); }

            }
        }


    @include breakpoint(medium){
        &_item{
            display: table;
        }

        &_hour-cell{
            padding-bottom: 0;
            width: 30%;
        }

        &_hour-cell,
        &_text-cell{
            display: table-cell;
            vertical-align: middle;
            float: none;
            padding: 1.4em 2.5em;

        }
    }
}
