.blue-border{
    border: 1px solid $primary-color;
    border-radius: $global-radius;
}

.marked-pink{
    color: $color_pink;
}

.marked{
    color: $color_pink;

}
// .bgcolor-blue .marked{
//     color: #fff;
// }


.relative{
    position: relative;
}

.absolute{
    position: absolute;

    &.bottom{
        bottom: 0;
    }
}


// FONTS
.lato{
    font-family: Lato;
}
.lora{
    font-family: Lora;
}


// OVERFLOWS
.overflow-hidden{
    overflow: hidden;
}
