.sticker-tear{
    background: $color_ffc700;

    border-radius: 200px 200px 0 200px;
    position: absolute;

    width: 200px;
    height: 200px;
    position: relative;
    text-align: center;
    color: #fff;

    .inner{
        padding: 1rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        p{
            line-height: 1;
            margin: 0;
        }
    }
    
}
