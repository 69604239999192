// SweetAlert2
// github.com/limonte/sweetalert2

.sweet-overlay {
  background-color: rgba(#000, .4);

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: none;
  z-index: 1000;
}

.sweet-alert {
  background-color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;

  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -200px;

  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  z-index: 2000;

  &:focus {
    outline: none;
  }

  &.loading {
    overflow-y: hidden;
  }

  h2 {
    color: #575757;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin: 0;
    padding: 0;
    line-height: 60px;
    display: block;
  }

  p {
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    position: relative;
    float: none;
    margin: 0;
    padding: 0;
    line-height: normal;
  }

  hr {
    height: 10px;
    color: transparent;
    border: 0;
  }

  button.styled {
    color: #fff;
    border: 0;
    box-shadow: none;
    font-size: 17px;
    font-weight: 500;
    border-radius: 5px;
    padding: 10px 32px;
    margin: 0 5px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &[disabled] {
      opacity: .4;
      cursor: no-drop;
    }

    &.loading {
      box-sizing: border-box;
      border: 4px solid transparent;
      border-color: transparent;
      width: 40px;
      height: 40px;
      padding: 0;
      margin: -2px 30px;
      vertical-align: top;
      background-color: transparent !important;
      color: transparent;
      cursor: default;
      border-radius: 100%;
      animation: rotate-loading 1.5s linear 0s infinite normal;
    }

    // Remove selection outline in Firefox
    &::-moz-focus-inner {
      border: 0;
    }
  }

  .sweet-image {
    margin: 20px auto;
  }

  .sweet-close {
    font-size: 36px;
    line-height: 36px;
    font-family: serif;
    position: absolute;
    top: 15px;
    right: 24px;
    cursor: pointer;
    color: #cfcfcf;
    transition: all 0.1s ease;

    &:hover {
      color: #d55;
    }
  }

}

.sweet-icon {
  $red:    #f27474;
  $orange: #f8bb86;
  $blue:   #c9dae1;
  $green:  #a5dc86;

  width: 80px;
  height: 80px;
  border: 4px solid #808080;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  cursor: default;

  /* http://stackoverflow.com/a/4407335/1331425 */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.sweet-error {
    border-color: $red;

    .x-mark {
      position: relative;
      display: block;
    }

    .line {
      position: absolute;
      height: 5px;
      width: 47px;
      background-color: $red;
      display: block;
      top: 37px;
      border-radius: 2px;

      &.left {
        transform: rotate(45deg);
        left: 17px;
      }

      &.right {
        transform: rotate(-45deg);
        right: 16px;
      }
    }
  }

  &.sweet-warning {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: $orange;
    border-color: $orange;
    font-size: 60px;
    line-height: 80px;
    text-align: center;
  }

  &.sweet-info {
    font-family: "Open Sans", sans-serif;
    color: $blue;
    border-color: $blue;
    font-size: 60px;
    line-height: 80px;
    text-align: center;
  }

  &.sweet-question {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: $blue;
    border-color: $blue;
    font-size: 60px;
    line-height: 80px;
    text-align: center;
  }

  &.sweet-success {
    border-color: $green;

    &::before,
    &::after { // Emulate moving circular line
      content: '';
      border-radius: 50%;
      position: absolute;
      width: 60px;
      height: 120px;
      background: #fff;
      transform: rotate(45deg);
    }

    &::before {
      border-radius: 120px 0 0 120px;
      top: -7px;
      left: -33px;

      transform: rotate(-45deg);
      transform-origin: 60px 60px;
    }

    &::after {
      border-radius: 0 120px 120px 0;
      top: -11px;
      left: 30px;

      transform: rotate(-45deg);
      transform-origin: 0 60px;
    }

    .placeholder { // Ring
      width: 80px;
      height: 80px;
      border: 4px solid rgba($green, .2);
      border-radius: 50%;
      box-sizing: content-box;

      position: absolute;
      left: -4px;
      top: -4px;
      z-index: 2;
    }

    .fix {  // Hide corners left from animation
      width: 7px;
      height: 90px;
      background-color: #fff;

      position: absolute;
      left: 28px;
      top: 8px;
      z-index: 1;

      transform: rotate(-45deg);
    }

    .line {
      height: 5px;
      background-color: $green;
      display: block;
      border-radius: 2px;

      position: absolute;
      z-index: 2;

      &.tip {
        width: 25px;

        left: 14px;
        top: 46px;

        transform: rotate(45deg);
      }

      &.long {
        width: 47px;

        right: 8px;
        top: 38px;

        transform: rotate(-45deg);
      }
    }
  }
}

// Animations

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  animation: #{$str};
}


// Modal animation

@include keyframes(showSweetAlert) {
  0% {
    transform: scale(.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(.95);
  }

  100% {
    transform: scale(1);
  }
}

@include keyframes(hideSweetAlert) {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(.5);
  }
}

.show-sweet-alert {
  @include animation('showSweetAlert 0.3s');

  &.no-animation {
    @include animation('none');
  }
}

.hide-sweet-alert {
  @include animation('hideSweetAlert 0.2s');

  &.no-animation {
    @include animation('none');
  }
}



// Success icon animation

@include keyframes(animate-success-tip) {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@include keyframes(animate-success-long) {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@include keyframes(rotatePlaceholder) {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

.animate-success-tip {
  @include animation('animate-success-tip 0.75s');
}

.animate-success-long {
  @include animation('animate-success-long 0.75s');
}

.sweet-icon.sweet-success.animate::after {
  @include animation('rotatePlaceholder 4.25s ease-in');
}


// Error icon animation

@include keyframes(animate-error-icon) {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.animate-error-icon {
  @include animation('animate-error-icon 0.5s');
}

@include keyframes(animate-x-mark) {
  0% {
    transform: scale(.4);
    margin-top: 26px;
    opacity: 0;
  }

  50% {
    transform: scale(.4);
    margin-top: 26px;
    opacity: 0;
  }

  80% {
    transform: scale(1.15);
    margin-top: -6px;
  }

  100% {
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

.animate-x-mark {
  @include animation('animate-x-mark 0.5s');
}

@include keyframes(pulse-warning) {
  0% {
    border-color: #f8d486;
  }

  100% {
    border-color: #f8bb86;
  }
}

.pulse-warning {
  @include animation('pulse-warning 0.75s infinite alternate');
}

@include keyframes(rotate-loading) {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
